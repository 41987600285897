import React, { JSX, PropsWithChildren, useState } from 'react';
import GeneralModal from './GeneralModal';
import { call } from '../../utils/ApiManager';
import { Podcast } from '../../types';
import cn from 'classnames';
import { Button } from '../button/Button';
import { hideModal } from '../../redux/actions/modal';
import { useDispatch } from 'react-redux';
import { info, success } from '../../utils/Notifications';
import { copyTextToClipboard } from '../../utils/utilities';

export const CreateRssLinkModal = ({ podcast }: { podcast: Podcast }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState('');
  const dispatch = useDispatch();

  const save = async () => {
    setIsLoading(true);
    await call('/rss')({ podcastId: podcast.id, selectedProvider });
    setIsLoading(false);
    dispatch(hideModal());
    success(
      'Hlekkurinn hefur verið búinn til. Afritaðu hlekkinn hér fyrir neðan.'
    );
  };
  const providers = [
    'Apple Podcasts',
    'Castbox',
    'Google Podcasts',
    'Podcast Addict',
    'Overcast',
    'Annað',
  ];
  return (
    <GeneralModal>
      <div className={cn('tw-p-8')}>
        <div className={cn('tw-font-600', 'tw-text-lg')}>Nýr RSS hlekkur</div>
        <div>Veldu hlaðvarpsforrit til að útbúa nýjan RSS hlekk.</div>
        <div
          className={cn(
            'tw-flex',
            'lg:tw-flex-row',
            'tw-flex-col',
            'tw-gap-4',
            'tw-mt-6'
          )}
        >
          {providers.map((provider) => (
            <SelectableButton
              onClick={() => setSelectedProvider(provider)}
              selected={selectedProvider === provider}
            >
              {provider}
            </SelectableButton>
          ))}
        </div>
        <div className={cn('tw-mt-7', 'tw-text-center')}>
          <Button className={'white'} onClick={() => dispatch(hideModal())}>
            Hætta við
          </Button>
          <Button
            disabled={!selectedProvider || isLoading}
            onClick={() => save()}
          >
            Staðfesta
          </Button>
        </div>
      </div>
    </GeneralModal>
  );
};
export const SelectableButton = ({
  selected,
  children,
  ...rest
}: PropsWithChildren<
  { selected?: boolean } & JSX.IntrinsicElements['button']
>) => {
  return (
    <button
      {...rest}
      className={cn('tw-rounded-md', 'tw-border', 'tw-px-3', 'tw-py-2', {
        [cn('tw-bg-purple-500', 'tw-text-white', 'hover:tw-purple-600')]:
          selected,
        [cn('hover:tw-bg-gray-100', 'tw-border-gray-300')]: !selected,
      })}
    >
      {children}
    </button>
  );
};
