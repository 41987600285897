import React, { useState } from 'react';
import s from './SubscriptionForm.module.css';
import { Center } from '../position/Center';
import { TitleCard } from '../title-card/TitleCard';
import { TextInput } from '../text-input/TextInput';
import { Button } from '../button/Button';
import { Dropdown } from '../dropdown/Dropdown';
import { SubscriptionPlan } from '../../types';

type Props = {
  title: string;
  save: (email: string, months: number) => void;
  isLoading: boolean;
  backRoute: string;
};

export const SubscriptionForm: React.FC<Props> = ({
  title,
  save,
  backRoute,
  isLoading,
}) => {
  const [email, setEmail] = useState<string>('');
  const [months, setMonths] = useState<number>(1);
  const onSave = () => {
    if (!months) {
      console.error('Fjöldi mánaða verður að vera til staðar.');
      return;
    }
    save(email, months);
  };

  const getMonthsString = (n: number) => {
    if (n === 1) {
      return `1 mánuður`;
    }
    return `${n} mánuðir`;
  };

  return (
    <Center>
      <div className={'tw-bg-white tw-w-[560px] tw-max-w-[100vw] tw-shadow-[0_0_10px_2px_rgba(0,0,0,0.05)] tw-p-6 tw-rounded-sm'}>
        <div className={'tw-font-600 tw-text-lg tw-text-center tw-mb-4'}>{title}</div>
        <TextInput
          autofocus
          value={email}
          label={'Netfang'}
          placeholder={'Skrifaðu netfang notanda'}
          onEnter={onSave}
          onChange={(val) => setEmail(val)}
        />
        <Dropdown
          selected={getMonthsString(months)}
          options={Array.from({ length: 12 }, (_, index) => index + 1).map(
            (months) => ({
              name: getMonthsString(months),
              value: months,
            }),
          )}
          onChange={(val) => setMonths(val.value)}
        />
        <div className={s.actions}>
          <Button className={'gray'} to={backRoute}>
            Til baka
          </Button>
          <Button disabled={isLoading || !email || !months} onClick={onSave}>
            {isLoading ? 'Hinkraðu...' : 'Áfram'}
          </Button>
        </div>
      </div>
    </Center>
  );
};
