import React, { useRef } from 'react';
//@ts-ignore
import { Player } from 'video-react';
import ReactHlsPlayer from 'react-hls-player';
import s from './FeedAttachments.module.css';
import Slider from 'react-slick';
import { FeedPostAttachment } from '../post-to-feed/FeedAttachments';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Vimeo from '@u-wave/react-vimeo';

type Props = {
  attachments: FeedPostAttachment[];
};
export const FeedAttachments: React.FC<Props> = ({ attachments }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const playerRef = useRef(null) as any;
  return (
    <div className={s.container}>
      <Slider {...settings}>
        {attachments.map((a) => {
          if (a.type === 'IMAGE') {
            return (
              <div className={s.image}>
                <img src={a.url + '?w=700'} alt={''} />
              </div>
            );
          }
          return (
            <div className={s.video}>
              {a.vimeo?.url && (
                <Vimeo
                  key={a.id}
                  video={a.vimeo.url}
                  autoplay={false}
                  responsive={true}
                />
              )}
              {a.klippari?.url && (
                <ReactHlsPlayer
                  key={a.id}
                  playerRef={playerRef}
                  src={a.klippari?.url || ''}
                  autoPlay={false}
                  controls={true}
                  width="100%"
                  height="auto"
                />
              )}
              {!a.vimeo?.url && !a.klippari?.url && (
                <Player key={a.id} src={a.url} />
              )}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
