import z from "zod";
import { dateStringSchema } from "../utils/zod-utils";

export const subscriptionPlanSchema = z.object({
  id: z.string(),
  months: z.number().optional(),
  price: z.number(),
  days: z.number().optional(),
  timeLimit: z
    .object({
      value: z.number(),
      unit: z.enum(['months', 'days']),
    })
    .optional().nullable(),
});

export const podcastSubscriptionSchema = z.object({
  id: z.string(),
  createdAt: dateStringSchema,
  endsAt: dateStringSchema,
  userEmail: z.string(),
  reference: z.string().optional(),
  subscriptionPlan: subscriptionPlanSchema
});

export const createManualSubscriptionRequest = z.object({
  email: z.string(),
  podcastId: z.string(),
  months: z.number()
})